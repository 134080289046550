<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.home") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div>
      <h2 class="mb-2">{{ t("headings.recent_notifications") }}</h2>
      <div class="card">
        <custom-table
          :fields="fields"
          :items="notifications"
          :loading="waiting"
          :pagination="false"
          :perPage="10"
          :searchable="false"
          :searchFields="[]"
          :showHeader="true"
          sortBy="createdAt"
          :ascending="false"
          :sortFields="sortFields"
          @row-click="(id) => router.push(`/notifications/${id}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
export default {
  components: { Breadcrumb, CustomTable, FormButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const notifications = computed(
      () => store.state.notification.notifications
    );

    const fields = ref([
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "type",
        label: "type",
      },
    ]);
    const sortFields = ref(["createdAt"]);

    return {
      fields,
      notifications,
      router,
      sortFields,
      t,
      waiting: computed(() => store.state.notification.waiting),
    };
  },
};
</script>
